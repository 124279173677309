import React, { useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";

import * as S from "./styles";

export const Banner: React.FC = () => {
  const history = useHistory();
  const animeBlackBall = {
    hidden: {
      scale: 1,
    },
    visible: {
      y: [0, 8, 0],
      x: [0, 4, 0],
      transition: { ease: "easeInOut", duration: 4, repeat: Infinity },
    },
  };
  const animeBigBall = {
    hidden: {
      scale: 1,
    },
    visible: {
      scale: [1, 1.025, 1],
      transition: { ease: "easeInOut", duration: 6, repeat: Infinity },
    },
  };

  const getGeoLocation = useCallback(() => {
    navigator.geolocation.getCurrentPosition(
      (position) =>
        console.log("coordenadas", {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        }),
      (error) => console.log("error", error)
    );
  }, []);

  useEffect(() => {
    getGeoLocation();
  }, [getGeoLocation]);
  return (
    <S.Container>
      <S.Content>
        <S.Caio />
        <S.BigBall initial="hidden" animate="visible" variants={animeBigBall} />
      </S.Content>
      <S.Content>
        <S.BlackBall
          initial="hidden"
          animate="visible"
          variants={animeBlackBall}
        />
        <S.Wrapper>
          <S.Title>
            Arrisque
            <br />
            <span>um sorriso</span>
          </S.Title>
          <S.Text>
            A Orthopride é a rede de clínicas de ortodontia que mais cresce no
            Brasil! Marque já a sua avaliação e descubra o que podemos fazer por
            você!
          </S.Text>
          <S.ButtonsWrapper>
            <S.Button
              btStyle="secondary"
              type="button"
              onClick={() => history.push("/encontre-uma-unidade")}
            >
              Encontre uma unidade
            </S.Button>
            <S.Button
              btStyle="light"
              type="button"
              onClick={() => history.push("/agendar-avaliacao")}
            >
              Agende online
            </S.Button>
          </S.ButtonsWrapper>
          {/* <S.Loncation>
            <S.Pin />
            Temos uma unidade em <span> Volta Redonda/RJ</span>
          </S.Loncation> */}
        </S.Wrapper>
      </S.Content>
    </S.Container>
  );
};
