import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { toast } from 'react-toastify';
import { ScrollFixer } from 'utils/ScrollFixer';

import Theme from 'styles/theme';
import './config/ReactotronConfig';
import { store, persistor } from './store';

import { Routes } from 'routes';
import TagManager from 'react-gtm-module';
import GlobalStyle from './styles/global';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

toast.configure();
TagManager.initialize({ gtmId: 'GTM-KVH9ZJW' });
const App: React.FC = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter basename="/">
          <ScrollFixer />
          <Theme>
            <GlobalStyle />
            <Routes />
          </Theme>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
};

export default App;
