import React from "react";
import { Switch, Route } from "react-router-dom";

import { Home } from "pages/Home";
import { Treatments } from "pages/Treatments";
import { Company } from "pages/Company";
import { CallCenter } from "pages/CallCenter";
import { CupomRegulament } from "pages/CupomRegulament";
import { Studio } from "pages/Studio";
import { Orofacial } from "pages/Orofacial";
import {
  Franchising,
  Interest as FranchisingInterest,
  Thanks as InterstThanks,
} from "pages/Franchising";
import { Franchising2024 } from "../pages/Franchising2024";
import { Ouvidoria, Thanks } from "pages/Ouvidoria";
import { Contact, Thanks as ContactThanks } from "pages/Contact";
import { FindUnit, UnitDetails } from "pages/Unit";
import { BookingFindUnit, NewBooking, BookingThanks } from "pages/Booking";
import { ThopSurf } from "pages/Thop_team";
// import { ParcelaPremiada } from "pages/ParcelaPremiada";

export const Routes: React.FC = () => {
  return (
    <>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/tratamentos" exact component={Treatments} />
        <Route path="/empresa" exact component={Company} />
        <Route path="/studioorthopride" exact component={Studio} />
        <Route path="/franchising" exact component={Franchising2024} />
        {/* <Route path="/franchising-2024" exact component={Franchising2024} /> */}
        <Route path="/regulamento" exact component={CupomRegulament} />
        <Route path="/harmonizacao-orofacial" exact component={Orofacial} />
        <Route
          path="/seja-um-franqueado/:capex"
          exact
          component={FranchisingInterest}
        />
        <Route path="/franqueado-obrigado/" exact component={InterstThanks} />
        <Route path="/duvidas-frequentes/" exact component={CallCenter} />
        <Route path="/ouvidoria" exact component={Ouvidoria} />
        <Route path="/ouvidoria-obrigado/" exact component={Thanks} />
        <Route path="/contato" exact component={Contact} />
        <Route path="/contato-obrigado/" exact component={ContactThanks} />
        <Route path="/encontre-uma-unidade" exact component={FindUnit} />
        <Route path="/unidade/:sigla/:name" exact component={UnitDetails} />
        <Route
          path="/unidade/:sigla/:name/:state"
          exact
          component={UnitDetails}
        />
        <Route path="/unidade/:id" exact component={UnitDetails} />
        <Route path="/agendar-avaliacao" exact component={BookingFindUnit} />
        <Route
          path="/agendar-avaliacao-tratamento/:lineo"
          exact
          component={BookingFindUnit}
        />
        <Route
          path="/agendar-avaliacao-campaign/:googleCampaign"
          exact
          component={BookingFindUnit}
        />
        <Route
          path="/agendar-avaliacao/:idUnidade"
          exact
          component={NewBooking}
        />
        <Route path="/avaliacao/" exact component={BookingThanks} />
        <Route path="/surf-thop-team/" exact component={ThopSurf} />
        <Route path="/surf-thop-team/:slug" exact component={ThopSurf} />
        {/* <Route path="/parcelapremiada/" exact component={ParcelaPremiada} />
        <Route
          path="/parcelapremiada/:idPaciente/:promoCode"
          exact
          component={ParcelaPremiada}
        /> */}
      </Switch>
    </>
  );
};
