import React, { useState } from "react";
import * as S from "./styles";

export const CookiesWarning: React.FC = () => {
  const [active, setActive] = useState<boolean>(true);
  return (
    <>
      <S.Container active={active}>
        <S.Text>
          Utilizamos dados de cookies para garantir o funcionamento do website,
          para analisar e personalizar nossos conteúdos durante a sua navegação
          em nossa plataforma e em serviços de terceiros parceiros. Ao navegar
          pelo site, você autoriza a Orthopride em coletar tais informações e
          utilizá-las para estas finalidades. Caso tenha dúvidas, consulte nossa{" "}
          <a target="_blank" href="PoliticadePrivacidadeOrthopride.pdf">
            Política de Privacidade
          </a>
        </S.Text>
        <S.CloseWarning onClick={() => setActive(false)}>Fechar</S.CloseWarning>
      </S.Container>
    </>
  );
};
