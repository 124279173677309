import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import InputMask from "react-input-mask";
import { useValidation } from "hooks";
import { notify } from "services";
import {
  CreateWhatsappContactActions,
  CreateWhatsappContactTypes,
} from "store/ducks/whatsappContact";

import * as S from "./styles";

export const FormMobile: React.FC = () => {
  const [formName, setFormName] = React.useState("");
  const [celNumber, setCelNumber] = React.useState("");
  const [formEmail, setFormEmail] = React.useState("");
  const [selectedState, setSelectedState] = React.useState("");
  const [selectedCity, setSelectedCity] = React.useState("");
  const [selectedInterState, setSelectedInterState] = React.useState("");
  const [selectedInterCity, setSelectedInterCity] = React.useState("");

  const [investiment, setInvestment] = React.useState("");
  const [investimentOptions, setInvestimentOptions] = useState([
    {
      label: "Até 400 Mil",
      value: "até 400mil",
    },
    {
      label: "Acima de 400 Mil",
      value: "acima de 400mil",
    },
  ]);

  const [apiResponse, setApiResponse] = useState([]);
  const [ufs, setUfs] = useState([]);
  const [citiesOptions, setCitiesOptions] = useState([
    {
      label: "Selecione o Estado",
      value: "",
    },
  ]);
  const [citiesInterOptions, setCitiesInterOptions] = useState([
    {
      label: "Selecione o Estado",
      value: "",
    },
  ]);

  const dispatch = useDispatch();

  const handleNumeroCelularChange = (e: any) => {
    setCelNumber(e.target.value);
  };

  interface Dado {
    label: string;
    value: string;
  }

  useEffect(() => {
    const initialValue = [
      {
        label: "",
        value: "",
      },
    ];
    setCitiesOptions(initialValue);
  }, []);

  //obtendo estados do brasil
  useEffect(() => {
    const fetchData = async () => {
      try {
        // consumindo conteúdo da api do IBGE
        const response = await fetch(
          "https://servicodados.ibge.gov.br/api/v1/localidades/distritos?view=nivelado"
        );
        const data = await response.json();
        setApiResponse(data);

        //abaixo buscando todos os estados brasileiros de forma única
        const ufsUnicos = data.reduce((acc: string[], current: any) => {
          const x = acc.find((item: string) => item === current["UF-sigla"]);
          if (!x) {
            return acc.concat([current["UF-sigla"]]);
          } else {
            return acc;
          }
        }, []);
        ufsUnicos.sort();
        setUfs(ufsUnicos);
      } catch (error) {
        console.error("Erro ao buscar dados da API:", error);
      }
    };

    fetchData();
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedState(event.target.value);
    const cities: Dado[] = apiResponse
      .filter((item) => item["UF-sigla"] === event.target.value)
      .map((item) => ({
        label: item["distrito-nome"],
        value: item["distrito-nome"],
      }));

    const orderCities = cities
      .slice()
      .sort((a, b) => a.label.localeCompare(b.label));

    setCitiesOptions(orderCities);
  };

  const handleChangeInter = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedInterState(event.target.value);
    const citiesInter: Dado[] = apiResponse
      .filter((item) => item["UF-sigla"] === event.target.value)
      .map((item) => ({
        label: item["distrito-nome"],
        value: item["distrito-nome"],
      }));

    const orderCities = citiesInter
      .slice()
      .sort((a, b) => a.label.localeCompare(b.label));

    setCitiesInterOptions(orderCities);
  };

  const handleSubmit = (event: any) => {
    event.preventDefault(); // Impede o comportamento padrão de recarregar a página ao enviar o formulário

    const payLoad = [
      {
        nome: formName,
        celular: celNumber,
        email: formEmail,
        estado: selectedState,
        cidade: selectedCity,
        investimento: investiment,
        cidade_interesse: selectedInterCity,
        estado_interesse: selectedInterState,
      },
    ];

    const formData = new FormData();
    const primeiroObjeto = payLoad[0];
    Object.entries(primeiroObjeto).forEach(([chave, valor]) => {
      formData.append(chave, valor);
    });

    try {
      dispatch(CreateWhatsappContactActions.request(formData));
      notify("success", "Cadastro Realizado com sucesso");
      setFormName("");
      setCelNumber("");
      setFormEmail("");
      setSelectedState("");
      setSelectedCity("");
      setInvestment("");
      setSelectedInterState("");
      setSelectedInterCity("");
      const message = encodeURIComponent(
        `Olá, me chamo ${formName} e me cadastrei no site de Franchising da Orthopride. Gostaria de conversar com um especialista para mais informações, para uma unidade em ${selectedCity}/${selectedState} com valor de investimento de ${investiment}. Meu e-mail é ${formEmail}, fico aguardando contato.`
      );
      const whatsappUrl = `https://api.whatsapp.com/send?phone=5521983357895&text=${message}`;
      window.open(whatsappUrl, "_blank");
    } catch (error) {
      console.log("Deu erro: ", error);
    }
  };

  return (
    <S.Container>
      <S.GroupForm>
        <p>Cadastre-se aqui e</p>
        <p className="orange">Seja um franqueado</p>
        <S.MyForm method="post" onSubmit={handleSubmit}>
          <p>
            <input
              type="text"
              className="field"
              placeholder="Nome completo"
              value={formName}
              required
              name="nome"
              onChange={(e) => setFormName(e.target.value)}
            />
          </p>
          <p>
            <InputMask
              type="text"
              className="field"
              placeholder="Celular"
              name="celular"
              mask="(99) 99999-9999"
              value={celNumber}
              required
              onChange={handleNumeroCelularChange}
            />
          </p>
          <p>
            <input
              type="email"
              className="field"
              value={formEmail}
              placeholder="E-mail"
              required
              name="email"
              onChange={(e) => setFormEmail(e.target.value)}
            />
          </p>
          <p>
            <select
              value={selectedState}
              required
              name="states"
              onChange={handleChange}
            >
              <option value="">Selecione o Estado</option>
              {ufs.map((uf, index) => (
                <option key={uf} value={uf}>
                  {uf}
                </option>
              ))}
            </select>
          </p>
          <p>
            <select
              required
              value={selectedCity}
              name="seletedCity"
              onChange={(e) => setSelectedCity(e.target.value)}
            >
              <option value="">Selecione a Cidade</option>
              {citiesOptions.map((option, index) => (
                <option key={index} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </p>

          <p>
            <select
              required
              value={investiment}
              name="investiment"
              onChange={(e) => setInvestment(e.target.value)}
            >
              <option value="">Capital de Investimento</option>
              {investimentOptions.map((option, index) => (
                <option key={index} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </p>
          <p>
            <select
              value={selectedInterState}
              required
              name="estado_interesse"
              onChange={handleChangeInter}
            >
              <option value="">Estado de Interesse</option>
              {ufs.map((uf, index) => (
                <option key={uf} value={uf}>
                  {uf}
                </option>
              ))}
            </select>
          </p>
          <p>
            <select
              required
              value={selectedInterCity}
              name="cidade_interesse"
              onChange={(e) => setSelectedInterCity(e.target.value)}
            >
              <option value="">Cidade de Interesse</option>
              {citiesInterOptions.map((option, index) => (
                <option key={index} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </p>
          <S.SubmitButton>Fale conosco</S.SubmitButton>
        </S.MyForm>
      </S.GroupForm>
    </S.Container>
  );
};
