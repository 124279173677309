import React from "react";
import * as S from "./styles";

export const Ambassadors: React.FC = () => {
  return (
    <>
      <S.Container>
        <S.GroupAmbassadors>
          <h2>
            Embaixadores
            <br />
            <span>Orthopride</span>
          </h2>
        </S.GroupAmbassadors>
        <S.GroupCards>
          <S.Card>
            <S.FaceMedina alt="Medina" />
            <h2>Gabriel Medina</h2>
            <p>
              Surfista
              <br />
              Tricampeão mundial
              <br /> 11,4 milhões de seguidores no Instagram
            </p>
          </S.Card>
          <S.Card>
            <S.FaceCaio alt="Caio Castro" />
            <h2>Caio Castro</h2>
            <p>
              Ator e piloto automobilístico
              <br /> 20,3 milhões de seguidores no Instagram
            </p>
          </S.Card>
          <S.Card>
            <S.FaceIan alt="Ian Consenza" />
            <h2>Ian Consenza</h2>
            <p>
              Surfista de Ondas Gigantes
              <br /> Piloto PWC
              <br /> 157.385 seguidores no Instagram
            </p>
          </S.Card>
          <S.Card>
            <S.FaceLucas alt="Lucas Chumbo" />
            <h2>Lucas Chumbo</h2>
            <p>
              Surfista de Ondas Gigantes
              <br /> 4x Nazare Tow Challenge
              <br /> 1.2 Milhão de seguidores no Instagram
            </p>
          </S.Card>
          <S.Card>
            <S.FaceFink alt="Lucas Fink" />
            <h2>
              Lucas
              <br /> Fink
            </h2>
            <p>
              Tricampeão mundial Skimboarder Skimboarding de Ondas Gigantes
              <br />
              245.523 seguidores no Instagram
            </p>
          </S.Card>
          <S.Card>
            <S.FaceMichele alt="Michelle Bouillons" />
            <h2>
              Michelle
              <br />
              Bouillons
            </h2>
            <p>
              Surfista de Ondas Gigantes
              <br /> Modelo e Apresentadora de TV
              <br /> 107.398 seguidores no Instagram
            </p>
          </S.Card>
        </S.GroupCards>
      </S.Container>
    </>
  );
};
